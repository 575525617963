import { Environment } from './interface';

export const environment: Environment = {
  production: false,
  apiBaseUrl: 'https://api-qa.latchaccess.com',
  selectAccount: null,
  useMocks: false,
  name: 'qa',
  enableLogging: true,
  datadogToken: 'pub14a05d477f7a66126a4bf7ce1d2c4169',
  enableAnalytics: true,
  heapApplicationId: '2664538431',
  reCaptchaToken: '6LfH_BgqAAAAANP8K2CDb_N_jkF6WBBUwgKvIAh5',
  auth0Config: {
    domain: 'auth.qa.latchaccess.com',
    clientId: 'j0rncCvWLOWI9w6xrIwaxqtaPyL8XVgV',
    redirectUri: window.location.origin,
    audience: 'https://api-qa.latchaccess.com',
    serverUrl: 'https://api-qa.latchaccess.com',
    httpInterceptor: {
      allowedList: [
        {
          uri: 'https://api-qa.latchaccess.com/web/v2/user-accounts/password/reset',
          allowAnonymous: true
        },
        'https://api-qa.latchaccess.com/web/*'
        // Ordering seems to matter here, though I can't find documentation of this fact.
        // We want to require tokens for /web/* paths, EXCEPT for /web/v2/user-accounts/password/reset
      ]
    }
  },
  enableFeaturedEntries: true,
  sprigEnvironmentId: 'h8BIfxkAQUzf',
  split: {
    core: {
      authorizationKey: 'vloi1tnvcof49gi683m8o4uspbgqgj0ic7gu',
      key: 'key'
    }
  },
  rudderstack: {
    writeKey: '24Z15TonaPJiDsvext558UEwU1b',
    dataPlaneUrl: 'https://rudderstack-proxy.qa.latchaccess.com'
  },
  doorAppUrl: 'https://app.qa.door.com/',
};
